<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           可对资产到期/长期空闲/报废的资产，进行线下出售/报废/转让，在系统中管理员可进行资产处置（处置后的资产在系统清单中不展示））。
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from './Common/Table.vue'
export default {
    components:{
		TableVue,
    },
	 inject:['reload'],
   data() {
      return {
		  tableTitle:'变更领用人',
		  storageType:9
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/AssetDisposal/index',
				add:'/AssetDisposal/add',
				edit:'/AssetDisposal/edit',
				del:'/AssetDisposal/del',
				export:'/AssetDisposal/export'
			} 
			this.$refs.c_table.cardName =  '资产处置单'
			// this.$refs.c_table.cardType = 2
			this.$refs.c_table.storageType =  9
			this.$refs.c_table.expandStatus = true
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>